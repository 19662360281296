import React from 'react'

function FeatureList({features}) {

    const featuresList = features.split(',');

    return (
        <ul className="mb-8 space-y-4 text-left">
            {featuresList.map((feature, index) => (
                <li key={index} className="flex items-center space-x-3 border-b-2 border-r-2">
                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                    <span>{feature.trim()}</span>
                </li>
            ))}
        </ul>
    )
}

export default FeatureList